import './styles.css'

function Footer(){
    return (
        <footer className="container footer">
            <hr></hr>
            <div>
                <p>Scandiweb Test assigment</p>
            </div>
        </footer>

    )
}

export default Footer;